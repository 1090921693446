import { createSlice } from '@reduxjs/toolkit'

const bookingSlice = createSlice({
    name: 'booking',
    initialState: {
        wipNo: '',
        appointmentId: ''
    },
    reducers: {
        wipNoChanged: (state, action) => {
            state.wipNo = action.payload
        },
        appointmentIdChanged: (state, action) => {
            state.appointmentId = action.payload
        }
    }
})

export const { wipNoChanged, appointmentIdChanged } = bookingSlice.actions
export default bookingSlice.reducer