
const initialState = {
    status: null
};


const statusReducer = (state = initialState, action) => {
    switch (action.type) {

    case 'RESET_STORE':
        return initialState;

    case 'statusChanged':
        return {
            ...state, status: action.payload
        }

    default:
        return state;
    }
}

export default statusReducer;