import React, { useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { getEpaasTenant, getEpaasLocale } from '../../functions/helperFunctions';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const CookiePolicy = () => {
    const intl = useIntl();

    useEffect(() => {
        const ePaasTenant = getEpaasTenant(intl.formatMessage({ id: 'frontPage.cookiePolicy.epaas.tenant' }));
        const ePaasLocale = getEpaasLocale(intl.locale);

        // cookie policy banner
        const script = document.createElement('script');
        script.src = "https://www.bmw.com/etc/clientlibs/wcmp/consentcontroller.fallback/epaas.js";
        script.async = true;
        script.onload = function () { epaas.api.initialize({ tenant: ePaasTenant, locale: ePaasLocale, async: true }); };
        document.head.appendChild(script);
    },[]);

    return (
        <div id="cookiePolicy">
            <Header />
            <div className="cookieInfoPageWrap">
                {/*ePaaS policy page injection container*/}
                <div className="epaas-policy-page-container"></div>

                <div className="cookie-info-back-home-item">
                    <a title={intl.formatMessage({ id: "cookiePolicy.returnLinkText" })} href="/" ><FormattedMessage id="cookiePolicy.returnLinkText" /></a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CookiePolicy;