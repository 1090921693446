import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import TimeSlots from '../TimeSlots/TimeSlots';
import DateSelection from '../DateSelection/DateSelection';
import { useIntl, FormattedDate, FormattedMessage } from 'react-intl';


const ServiceTimeSelection = () => {

    const carDetails = useSelector(state => state.carDetails);
    const dealers = useSelector(state => state.dealer);
    const serviceTime = useSelector(state => state.serviceTime);
    const selectedTimeSlot = useSelector(state => state.timeSlot);
    const services = useSelector(state => state.services);
    const history = useHistory();
    const intl = useIntl();
    const showSectionData = carDetails.vehicleFromBackend && parseInt(dealers.currentDealer.DealerId) !== 0 && services.totalDuration !== 0;
    const daysOfWeekShort = daysForLocale('short');
    const daysOfWeekLong = daysForLocale('long');
    const months = monthsForLocale();


    const goToSummaryPage = () => {
        history.push('/reservation');
    };

    function daysForLocale(format) {
        return [...Array(7).keys()]
            .map((day) => intl.formatDate(new Date(2021, 3, day + 4), { weekday: format }));
    }

    function monthsForLocale() {
        return [...Array(12).keys()]
            .map((month) => intl.formatDate(new Date(2021, month, 1), { month: 'long' }));
    }


    return (
        <section id="service-time" className="margin-top-m">
            <div className="container">
                <div className="row d-flex">
                    <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-m-scale padding-hor-l-scale hr-top">
                        <h2 className="visual-h3">4. <FormattedMessage id="serviceTimeSelection.header" /></h2>
                        <div>
                            {
                                !showSectionData &&
                                <span><FormattedMessage id="serviceTimeSelection.message.giveServicesFirst" /></span>
                                ||
                                <div className="service-calendar d-lg-flex justify-content-evenly">
                                    <DateSelection daysOfWeekLong={daysOfWeekLong} daysOfWeekShort={daysOfWeekShort} months={months} />
                                    <TimeSlots />
                                </div>
                            }
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-between margin-top-m">
                            {
                                showSectionData && !selectedTimeSlot.selectedTimeSlot &&
                                <span>
                                    <span className="margin-bottom-s-md-down"><FormattedMessage id="serviceTimeSelection.selectDateFromCalendar" /></span>
                                </span>
                            }
                            {
                                showSectionData && selectedTimeSlot.selectedTimeSlot && serviceTime.selectedDate &&
                                <div>
                                    <span className="margin-bottom-s-md-down">
                                        <FormattedMessage id="serviceTimeSelection.selectedDateTime" />:&nbsp;
                                        <span className="bold">
                                            {intl.formatDate(serviceTime.selectedDate, { weekday: 'long' })}&nbsp;
                                            {intl.formatDate(serviceTime.selectedDate)}&nbsp;
                                            <FormattedMessage id="serviceTimeSelection.clock" />&nbsp;
                                            {selectedTimeSlot.selectedTimeSlot.StartTime}
                                        </span>
                                    </span>
                                </div>
                            }
                            <button type="submit" disabled={!selectedTimeSlot.selectedTimeSlot || !serviceTime.selectedDate} className="bmw-btn btn-primary margin-left-s-md-up" onClick={() => goToSummaryPage()}>
                                <FormattedMessage id="serviceTimeSelection.button.continue" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceTimeSelection;