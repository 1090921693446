import React, { useEffect } from 'react';

const TooltipButton = ({ tooltipFunction, tooltipParameter }) => {



    return (
        <span>
            <img src="/Scripts/src/media/icons/consent-info.svg" className="moreinfo" alt="infoButton"
                onKeyPress={() => tooltipFunction(tooltipParameter)}
                onClick={() => tooltipFunction(tooltipParameter)} tabIndex="0">
            </img>
        </span>
    );
}

export default TooltipButton;