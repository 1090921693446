import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import carDetailsReducer from './reducers/carDetailsReducer';
import dealerReducer from './reducers/dealerReducer';
import servicesReducer from './reducers/servicesReducer';
import serviceTimeReducer from './reducers/serviceTimeReducer';
import timeSlotReducer from './reducers/timeSlotReducer';
import customerReducer from './reducers/customerReducer';
import statusReducer from './reducers/statusReducer';
import languageReducer from './reducers/languageReducer';
import queryParameterReducer from './reducers/queryParameterReducer';
import bookingReducer from './reducers/bookingReducer'

const store = configureStore({
    reducer: {
        carDetails: carDetailsReducer,
        dealer: dealerReducer,
        services: servicesReducer,
        serviceTime: serviceTimeReducer,
        timeSlot: timeSlotReducer,
        customer: customerReducer,
        status: statusReducer,
        language: languageReducer,
        queryParameter: queryParameterReducer,
        booking: bookingReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export default store;