import { createSlice } from '@reduxjs/toolkit';

const queryParameterSlice = createSlice({
    name: 'queryParameter',
    initialState: {
        queryParameter: ''
    },
    reducers: {
        queryParameterChanged: (state, action) => {
            state.queryParameter = action.payload;
        }
    }
});

export const { queryParameterChanged } = queryParameterSlice.actions;
export default queryParameterSlice.reducer;