import { createSlice } from '@reduxjs/toolkit'

const languageSlice = createSlice({
    name: 'language',
    initialState: {
        locale: '',
        messages: null
    },
    reducers: {
        localeChanged: (state, action) => {
            state.locale = action.payload
        },
        messagesChanged: (state, action) => {
            state.messages = action.payload
        }
    }
})

export const { localeChanged, messagesChanged } = languageSlice.actions
export default languageSlice.reducer