import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { localeChanged, messagesChanged } from '../../reducers/languageReducer';
import { queryParameterChanged } from '../../reducers/queryParameterReducer';
import { FormattedMessage, useIntl, createIntl, createIntlCache } from 'react-intl';
import Finnish from '../../lang/fi.json';
import English from '../../lang/gb.json';
import Swedish from '../../lang/se.json';
import Norwegian from '../../lang/no.json';
import Estonian from '../../lang/ee.json';
import Latvian from '../../lang/lv.json';
import Danish from '../../lang/da.json';
import Lithuanian from '../../lang/lt.json';

const Header = ({ title, description }) => {
    const dispatch = useDispatch();
    const browserCulture = document.documentElement.dataset.culture;
    const pageTopClass = window.location.href.includes('/tirechange') ? 'tire-change' : 'leasing';

    const changeLanguage = (e, langObj) => {
        e.preventDefault();

        dispatch(localeChanged(langObj.lang));
        dispatch(messagesChanged(langObj.messages));

        const browserCountryCode = browserCulture.split('-')[1];
        const lang = langObj.lang.split('-')[0];

        const queryParam = `?countryCode=${browserCountryCode}&lang=${lang}`;

        dispatch(queryParameterChanged(queryParam));
    };

    const intl = useIntl();
    const countryCode = intl.locale.split('-')[1];

    const queryParams = useSelector(state => state.queryParameter.queryParameter);

    const availableLanguages = document.documentElement.dataset.languages;

    const languages = availableLanguages.split(",").map(l => {
        if (l === 'fi') return { text: 'Suomeksi', lang: 'fi-FI', messages: Finnish };
        if (l === 'sv') return { text: 'På svenska', lang: 'sv-SE', messages: Swedish };
        if (l === 'en') return { text: 'In English', lang: 'en-GB', messages: English };
        if (l === 'nb') return { text: 'På norsk', lang: 'nb-NO', messages: Norwegian };
        if (l === 'da') return { text: 'På dansk', lang: 'da-DK', messages: Danish };
        if (l === 'lv') return { text: 'Latviešu valodā', lang: 'lv-LV', messages: Latvian };
        if (l === 'et') return { text: 'Eesti keeles', lang: 'et-EE', messages: Estonian };
        if (l === 'lt') return { text: 'Lietuvių kalba', lang: 'lt-LT', messages: Lithuanian }; 
    });

    const intlCache = createIntlCache();

    const browserCultureMessages = browserCulture === 'sv-SE' ? Swedish :
        browserCulture === 'en-GB' ? English :
        browserCulture === 'nb-NO' ? Norwegian :
        browserCulture === 'da-DK' ? Danish :
        browserCulture === 'lv-LV' ? Latvian :
        browserCulture === 'et-EE' ? Estonian :
        browserCulture === 'lt-LT' ? Lithuanian :
        Finnish;

    const currentLocaleIntl = createIntl({
        locale: browserCulture,
        messages: browserCultureMessages
    }, intlCache);

    return (
        <div>
            <div id="page-top" className={pageTopClass}>
                <header id="page_header">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-bg">
                            <div className="container-fluid">
                                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon">&nbsp;</span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a className="nav-link" href={countryCode === 'GB' ? currentLocaleIntl.formatMessage({ id: "header.models.link.url" }) : intl.formatMessage({ id: "header.models.link.url" })} target="_blank">
                                                <FormattedMessage id="header.models.link" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href={countryCode === 'GB' ? currentLocaleIntl.formatMessage({ id: "header.services.link.url" }) : intl.formatMessage({id: "header.services.link.url" })} target="_blank">
                                                <FormattedMessage id="header.services.link" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href={countryCode === 'GB' ? currentLocaleIntl.formatMessage({ id: "header.discover.link.url" }) : intl.formatMessage({ id: "header.discover.link.url" })} target="_blank">
                                                <FormattedMessage id="header.discover.link" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href={countryCode === 'GB' ? currentLocaleIntl.formatMessage({ id: "header.electric.link.url" }) : intl.formatMessage({ id: "header.electric.link.url" })} target="_blank">
                                                <FormattedMessage id="header.electric.link" />
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href={countryCode === 'GB' ? currentLocaleIntl.formatMessage({ id: "header.find.link.url" }) : intl.formatMessage({ id: "header.find.link.url" })} target="_blank">
                                                <FormattedMessage id="header.find.link" />
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="navbar-languages">
                                        {languages.map(langObj => {
                                            return (
                                                <li key={langObj.lang} className="nav-item">
                                                    <a id="" href="#" role="link" className="nav-link" onClick={(e) => changeLanguage(e, langObj)}>
                                                        {langObj.text}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <Link className="navbar-brand logo" to={`/${queryParams}`}>
                                    <img src="/Scripts/src/media/icons/BMW_logo_white.svg" alt="BMW logo" />
                                </Link>
                            </div>
                        </nav>
                    </div>
                </header>
                {
                    (title || description) &&
                    <div className="container lead margin-top-m-scale-md-up">
                        <h1>{title}</h1>
                        <p>{description}</p>
                    </div>
                }
            </div>
        </div>
    );
};

export default Header;