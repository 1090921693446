
const initialState = {
    selectedTimeSlot: null,
    reservedTime: null
};


const timeSlotReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_STORE':
            return initialState;

        case 'timeSlotSelected':
            return {
                ...state, selectedTimeSlot: action.payload
            }

        case 'timeSlotUnselected':
            return {
                ...state, selectedTimeSlot: null
            }

        case 'reservedTimeUpdated':
            return {
                ...state, reservedTime: action.payload
            }

        default:
            return state;
    }
}

export default timeSlotReducer;