import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

const SelectedTimeDetails = () => {
    const serviceTime = useSelector(state => state.serviceTime);
    const selectedTimeSlot = useSelector(state => state.timeSlot);
    const intl = useIntl();

    return (
        <div>
            <div className="visual-h4 padding-top-s-md-down bold"><FormattedMessage id="summary.selectedTime" /></div>
            {
                selectedTimeSlot && selectedTimeSlot.selectedTimeSlot &&
                <p>{intl.formatDate(serviceTime.selectedDate, { weekday: 'long' })} {intl.formatDate(serviceTime.selectedDate)} <FormattedMessage id="summary.selectedTime.clock" /> {selectedTimeSlot.selectedTimeSlot.StartTime}</p>
            }
        </div>
    );
};

export default SelectedTimeDetails;