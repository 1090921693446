import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MANAGEMENT_SYSTEM } from '../../enums/managementSystem';
import { lockSelectedCalendarTime, createPreliminaryServiceBooking } from '../../services/dataService';
import { FormattedMessage } from 'react-intl';
import { wipNoChanged, appointmentIdChanged } from '../../reducers/bookingReducer'

const TimeSlots = () => {
    const dispatch = useDispatch();
    const selectedTimeSlot = useSelector(state => state.timeSlot);
    const serviceTime = useSelector(state => state.serviceTime);
    const dealers = useSelector(state => state.dealer);
    const carDetails = useSelector(state => state.carDetails);
    const services = useSelector(state => state.services);


    const selectTimeSlot = async (timeSlot) => {
        if (selectedTimeSlot.selectedTimeSlot && selectedTimeSlot.selectedTimeSlot.StartTime) {
            dispatch({
                type: 'timeSlotUnselected',
                payload: timeSlot
            });

            return;
        }

        dispatch({
            type: 'timeSlotSelected',
            payload: timeSlot
        });

        const reservedTime = createReservedTime(timeSlot);
        console.log('reservedTime ', reservedTime);

        dispatch({
            type: 'reservedTimeUpdated',
            payload: reservedTime
        });

        if (dealers.currentDealer.ManagementSystem === MANAGEMENT_SYSTEM.Automaster) {
            const response = await createPreliminaryServiceBooking(dealers.currentDealer.DealerId, carDetails.vehicleFromBackend, reservedTime);

            if (response && response.ErrorCode === '0') {
                dispatch(wipNoChanged(response.WipNo.toString()));
                dispatch(appointmentIdChanged(response.AppointmentId.toString()));
            }
        } else if (dealers.currentDealer.ManagementSystem === MANAGEMENT_SYSTEM.Solteq ||
            dealers.currentDealer.ManagementSystem === MANAGEMENT_SYSTEM.SolteqAPI) {

            const startDateTime = serviceTime.selectedDate.toDateString() + ' ' + timeSlot.StartTime;
            const endDateTime = serviceTime.selectedDate.toDateString() + ' ' + timeSlot.EndTime;

            await lockSelectedCalendarTime(startDateTime,
                endDateTime,
                services.totalDuration / 12, // total services duration in hours
                timeSlot.MechanicId,
                dealers.currentDealer.DealerId);
        }

    }

    function createReservedTime(timeSlot) {

        let startTime = new Date(serviceTime.selectedDate);
        startTime.setHours(timeSlot.StartTime.split(':')[0]);
        startTime.setMinutes(timeSlot.StartTime.split(':')[1]);

        let endTime = new Date(serviceTime.selectedDate);
        endTime.setHours(timeSlot.EndTime.split(':')[0]);
        endTime.setMinutes(timeSlot.EndTime.split(':')[1]);

        let reservedTime = {
            MechanicId: timeSlot.MechanicId,
            Level: 'S',
            Date: new Date(serviceTime.selectedDate),
            StartTime: startTime,
            EndTime: endTime,
            Duration: services.totalDuration / 12 // total services duration in hours

        }

        return reservedTime;
    }

    return (
        <div className="TimePicker">
            {
                serviceTime.timeSlots && serviceTime.timeSlots.length > 0 &&
                <div className="calendar-header"><FormattedMessage id="timeSlots.header" /></div>
            }
            {
                serviceTime.timeSlots && serviceTime.timeSlots.length > 0 &&
                serviceTime.timeSlots.map((slot, index) =>
                    <span key={(index + 1) * 50}>
                        {
                            <span className={selectedTimeSlot.selectedTimeSlot && selectedTimeSlot.selectedTimeSlot.StartTime === slot.StartTime ? 'TimePicker-Time--selected' : ''} key={index} hidden={slot.hidden} onKeyPress={(event) => selectTimeSlot(slot)} onClick={() => selectTimeSlot(slot)} tabIndex="0"> {slot.StartTime}
                            </span>
                        }
                    </span>

                )
            }

        </div>
    );
}

export default TimeSlots;