import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import CustomerInfo from '../CustomerInfo/CustomerInfo';
import ThankYouPage from '../ThankYouPage/ThankYouPage';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';
import { useSelector, useDispatch } from 'react-redux';
import { saveReservation } from '../../services/dataService';
import { useIntl, FormattedDate, FormattedMessage } from 'react-intl';
import DealerDetails from '../DealerDetails/DealerDetails';
import SelectedTimeDetails from '../SelectedTimeDetails/SelectedTimeDetails';

const Summary = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const carDetails = useSelector(state => state.carDetails);
    const currentDealer = useSelector(state => state.dealer.currentDealer);
    const services = useSelector(state => state.services);
    const selectedTimeSlot = useSelector(state => state.timeSlot);
    const customer = useSelector(state => state.customer);
    const status = useSelector(state => state.status);
    const booking = useSelector(state => state.booking);
    const language = useSelector(state => state.language);
    const intl = useIntl();

    const browserCulture = document.documentElement.dataset.culture;

    const bookingLanguage = language.locale ? language.locale.split('-')[0] : browserCulture.split('-')[0];

    const currencyUnit = (browserCulture === 'sv-SE' || browserCulture === 'nb-NO' || browserCulture === 'da-DK') ? " kr" : " \u20AC";

    const selectedServices = services.services.concat(services.additionalServices).concat(services.leasingServices).filter(s => s.Selected === true);
    const totalPrice = selectedServices.reduce((previous, current) => previous + (current.PriceVAT > 0 ? current.PriceVAT : 0), 0);

    const [sendingReservation, setSendingReservation] = useState(false);

    const returnToPreviousPage = () => {
        dispatch({
            type: 'isReturnFromSummaryPage', payload: true
        });
        history.goBack();
    };

    const handleClick = (e) => {
        e.preventDefault();

        setSendingReservation(true);

        sendReservation();
    };

    const sendReservation = async () => {
        const customerInfo = {
            FirstName: customer.FirstName.value,
            LastName: customer.LastName.value,
            Email: customer.Email.value,
            PhoneNumber: customer.PhoneNumber.value,
            Info: customer.Info.value,
            MarketingAllowed: customer.MarketingAllowed.value,
            AnalyticsAllowed: customer.MarketingAllowed.value,
            RemoteKeyRead: false
        };

        const reservation = {
            BrandCode: 1,
            DealerId: currentDealer ? currentDealer.DealerId : null,
            Dealer: currentDealer,
            Vehicle: carDetails.vehicleFromBackend,
            ReservedTime: selectedTimeSlot.reservedTime,
            Customer: customerInfo,
            AdditionalServices: selectedServices,
            ServicePackages: null,
            Booking: booking
        };

        let reservationStatus = await saveReservation(reservation);
        console.log('status ', reservationStatus);

        dispatch({
            type: 'statusChanged', payload: reservationStatus ? reservationStatus.status : null
        });

        setSendingReservation(false);
    };

    const getTyreServiceNameTranslation = (service) => {
        const category = service.Category;
        let translation = category === 'LEASING' ? service.Description : service.Name;

        if (browserCulture === 'fi-FI') {
            if (category === 'LEASING') {
                if (bookingLanguage === 'sv' && service.Description2) translation = service.Description2;
                else if (bookingLanguage === 'en' && service.Description3) translation = service.Description3;
            }
            else {
                if (bookingLanguage === 'sv' && service.Name2) translation = service.Name2;
                else if (bookingLanguage === 'en' && service.Name3) translation = service.Name3;
            }
        }
        else if (browserCulture !== 'en-GB' && bookingLanguage === 'en') {
            if (category === 'LEASING' && service.Description2) {
                translation = service.Description2;
            }
            else if (service.Name2) {
                translation = service.Name2;
            }
        }

        return translation;
    };

    function formatDecimalNumber(amount, varyingPrice) {
        if ((typeof varyingPrice !== 'undefined') && varyingPrice && amount < 0)
            return (intl.formatMessage({ id: 'servicesSelection.varyingPrice' }));
        else
            return Math.round(amount);
    }

    return (
        <div id="page3">
            <Header title={<FormattedMessage id="summary.header" />} />
            <main id="main">
                {
                    status.status === true &&
                    <ThankYouPage />
                    ||
                    <div>
                        <section className="margin-top-m">
                            <div className="container">
                                <div className="row d-flex">
                                    <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-l-scale padding-hor-l-scale d-md-flex">
                                        <div className="summary d-md-flex flex-column col-8">
                                            <DealerDetails />
                                            <div className="d-sm-flex justify-content-sm-between flex-md-column">
                                                <div>
                                                    <div className="visual-h4 padding-top-s-md-down bold"><FormattedMessage id="summary.carDetails" /></div>
                                                    <p>
                                                        <span>{carDetails.license.value}</span><br />
                                                        {
                                                            carDetails.vehicleFromBackend &&
                                                            <span>{carDetails.vehicleFromBackend.ModelSeries}</span>
                                                        }
                                                    </p>
                                                </div>
                                                <SelectedTimeDetails />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="visual-h4 padding-top-s-md-down bold"><FormattedMessage id="summary.selectedServices" /></div>
                                            <ul className="selected-services">
                                                {
                                                    selectedServices.map((service, index) =>
                                                        <li key={index}>
                                                            {getTyreServiceNameTranslation(service)}
                                                            <span>
                                                                {formatDecimalNumber(service.PriceVAT, true)}
                                                                {currencyUnit}
                                                            </span>
                                                        </li>
                                                    )
                                                }
                                                <li><FormattedMessage id="summary.summary" /> {formatDecimalNumber(totalPrice)}{currencyUnit}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <CustomerInfo />
                        <section id="finalize-booking" className="margin-top-m">
                            <div className="container">
                                <div className="row">
                                    {!status.status &&
                                        <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-hor-l-scale d-md-flex justify-content-end">
                                            <button type="submit" className="bmw-btn btn-primary margin-left-s-md-up order-md-2" title="" disabled={customer.HasInputErrors === true} onClick={(e) => handleClick(e)}>
                                                <FormattedMessage id="summary.button.submit" />
                                            </button>
                                            <button type="button" id="back-btn" title="" onClick={() => returnToPreviousPage()} className="bmw-btn btn-secondary">
                                                <FormattedMessage id="summary.button.return" />
                                            </button>
                                        </div>
                                    }
                                    {sendingReservation ? <LoaderSpinner /> : null }
                                    {status.status === false &&
                                        <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-hor-l-scale d-md-flex">
                                            <b><FormattedMessage id="summary.message.reservationDidNotSucceed" /></b>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                }
            </main>
            <Footer />
        </div>
    );
}

export default Summary;

