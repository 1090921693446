import axios from 'axios';

const headers = {
    __RequestVerificationToken: document.getElementsByName("__RequestVerificationToken")[0].value,
    'X-Requested-With': 'XMLHttpRequest'
};

const getVehicleInfo = async (license, vehicleIdNumber, mileage, dealerId, countryCode) => {
    //countrycode in queryString is for testing environments
    const baseUrl = '/vehiclesearch/getvehiclebylicensenumber?countryCode=' + countryCode;

    // one mileage unit is 1000 km in Finland and 1 mil in Sweden ( 1 mil === 10 km)
    const mils = countryCode === 'FI' ? mileage * 1000 : mileage;

    const params = { licenseNumber: license, vehicleIdNumber: vehicleIdNumber, mileage: mils, dealerId: dealerId };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        if (res.data) {
            res.data.ModelSeries = 'BMW ' + res.data.ModelSeries;
        }

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

async function servicesFromBackend(dealerId, serviceType) {
    const baseUrl = '/tireChange/getservices';
    const params = { serviceType: serviceType, dealerId: dealerId };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
}

async function leasingServicesFromBackend(dealerId) {
    const baseUrl = '/services/getleasingservices';
    const params = { dealerId: dealerId };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
}

export const getServices = async (dealerId) => {
    const services = await servicesFromBackend(dealerId, 1);
    const additionalServices = await servicesFromBackend(dealerId, 2);
    const leasingServices = await leasingServicesFromBackend(dealerId);

    return {
        services,
        additionalServices,
        leasingServices
    };
};

export const getLeasingServices = async () => {
    const leasingServices = await leasingServicesFromBackend();

    return leasingServices;
};


export const getWeekSchedule = async (dealerId) => {
    const baseUrl = '/tireChange/gettirechangeparameters';
    const params = { dealerId: dealerId };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

export const getAvailableServiceTimes = async (dealerId, licenseNumber, numberOfWeeks, duration, calendarStartTime, timeSlot, managementSystem, calendarBuffer, startHoursMinutes, reservationKind) => {
    const baseUrl = '/calendar/getavailableservicetimes';

    const params = {
        dealerId: dealerId,
        licenseNumber: licenseNumber,
        level: 'S',
        numberOfWeeks: numberOfWeeks,
        duration: duration,
        calendarStartTime: calendarStartTime,
        timeSlot: timeSlot,
        managementSystem: managementSystem,
        calendarBuffer: calendarBuffer,
        reservationKind: reservationKind,
        startHoursMinutes: startHoursMinutes
    };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

export const getDealerInfo = async (dealers, countryCode) => {
    //countrycode in queryString is for testing environments
    const baseUrl = dealers ? '/dealer/getfiltereddealerlist' : '/dealer/getdealers?countryCode=' + countryCode;
    const params = dealers ? { dealerIds: dealers } : { countryCode: countryCode };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

export const getTireChangeParametersOfAllDealers = async (dealerIds) => {
    const baseUrl = '/tirechange/gettirechangeparametersofalldealers';

    try {
        const res = await axios.post(baseUrl, { dealerIds: dealerIds }, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

export const getDealerCalendarParameters = async (dealerId) => {
    const baseUrl = '/dealer/getdealerparameters';

    try {
        const res = await axios.post(baseUrl, { dealerId: dealerId }, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

export const createPreliminaryServiceBooking = async (dealerId, vehicle, reservedTime) => {
    const baseUrl = '/calendar/createPreliminaryServiceBooking';
    const params = {
        dealerId: dealerId,
        vehicle: vehicle,
        reservedTime: reservedTime
    };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

export const lockSelectedCalendarTime = async (time, endTime, duration, mechanicId, dealerId) => {
    const baseUrl = '/calendar/lockSelectedCalendarTime';
    const params = {
        time: time,
        endTime: endTime,
        duration: duration,
        mechanicId: mechanicId,
        dealerId: dealerId
    };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

export const saveReservation = async (reservation) => {
    const baseUrl = '/reservation/savereservation';
    const params = {
        reservation: reservation
    };

    try {
        const res = await axios.post(baseUrl, params, { headers: headers });

        return res.data;
    }
    catch (error) {
        console.log('err: ', error);
        return null;
    }
};

export default {
    getVehicleInfo
};