
const initialState = {
    serviceTimes: null,
    availableServiceTimes: null,
    timeSlots: [],
    selectedDate: null,
    disabledDates: [],
    leasingStartDate: null,
    leasingEndDate: null
};

const serviceTimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'RESET_STORE':
            return initialState;

        case 'availableServiceTimesAdded':
            return {
                ...state,
                availableServiceTimes: action.payload
            }

        case 'availableServiceTimesDeleted':
            return {
                ...state,
                availableServiceTimes: null
            }

        case 'timeSlotsAdded':
            return {
                ...state,
                timeSlots: action.payload
            }

        case 'timeSlotsDeleted':
            return {
                ...state,
                timeSlots: null
            }

        case 'dateSelected':
            return {
                ...state,
                selectedDate: action.payload
            }

        case 'dateUnselected':
            return {
                ...state,
                selectedDate: null
            }

        case 'datesDisabled':
            return {
                ...state,
                disabledDates: action.payload
            }

        case 'datesUndisabled':
            return {
                ...state,
                disabledDates: null
            }

        case 'leasingStartDateChanged':
            return {
                ...state,
                leasingStartDate: action.payload
            }

        case 'leasingEndDateChanged':
            return {
                ...state,
                leasingEndDate: action.payload
            }

        default:
            return state;
    }
}

export default serviceTimeReducer;