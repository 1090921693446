
const initialState = {
    license: { value: '', error: 'carDetails.license.error.missing' },
    mileage: { value: 0, error: 'carDetails.mileage.error.missing' },
    vinNumber: '',
    vehicleFromBackend: null,
    vehicleSearching: false,
    mileageTooltipTextVisibility: 'hide'
};

const carDetailsReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'RESET_STORE':
            return initialState;

        case 'mileageInfoChanged':
            const mileageError = handleValidation(action.payload, 'mileage', null);

            return {
                ...state, mileage: { value: action.payload, error: mileageError }, vehicleFromBackend: null
            }

        case 'licenseInfoChanged':
            const licenseError = handleValidation(action.payload.license, 'license', action.payload.locale);

            return {
                ...state, license: { value: action.payload && action.payload.license ? action.payload.license.toUpperCase() : null, error: licenseError }, vehicleFromBackend: null
            }

        case 'vinNumberChanged':
            return {
                ...state, vinNumber: action.payload
            }

        case 'vehicleFromBackendAdded':
            return {
                ...state, vehicleFromBackend: action.payload, license: { value: state.license.value, error: "noError" }
            }

        case 'vehicleFromBackendNotFound':
            return {
                ...state, vehicleFromBackend: null, license: { value: state.license.value, error: "carDetails.license.error.vehicleNotFound" }
            }

        case 'vehicleSearching':
            return {
                ...state, vehicleSearching: action.payload
            }
        case 'mileageTooltipTextVisibilityChanged':
            return {
                ...state, mileageTooltipTextVisibility: action.payload
            }

        default:
            return state;
    }
}


function handleValidation(fieldValue, fieldName, locale) {

    if (fieldName === 'license' && !fieldValue) {
        return "carDetails.license.error.missing";
    }

    if (fieldName === 'license' && typeof fieldValue !== "undefined") {
        if (!isValidLicenseNumber(fieldValue, locale)) {
            return "carDetails.license.error.invalidValue";
        }
    }

    if (fieldName === 'mileage' && (!fieldValue || parseInt(fieldValue) === 0)) {
        return "carDetails.mileage.error.missing";
    }

    if (fieldName === 'mileage' && fieldValue < 0) {
        return "carDetails.mileage.error.invalidValue";
    }

    return "noError";
}

function isValidLicenseNumber(licenseNumber, locale) {

    switch (locale) {
        case 'fi-FI':
            return licenseNumber.match(/^[A-Za-z]{3}-\d{3}$/);

        case 'sv-SE':
            return licenseNumber.match(/(^[A-ZÄÖÅ]{3}\d{3,3}$|^[A-ZÄÖÅ]{3}\d{2,2}[A-ZÄÖÅ]{1}$)/);

        case 'et-EE':
            return licenseNumber.match(/^[0-9A-Z]{2,9}$/);

        case 'lv-LV':
            return licenseNumber.match(/^[A-Z]{2}[0-9A-Z]{1,4}$/);

        case 'da-NO':
        case 'da-DK':
            return licenseNumber.match(/^[A-Z]{2}\d{4,5}$/);

        default:
            {
                //license number regex https://github.com/pear/Validate_FI/blob/master/Validate/FI.php

                // diplomat licence plate
                if (licenseNumber.match(/^CD-[1-9]{1}[0-9]{0,3}$/)) {
                    return true;
                }

                // other tax-free diplomat licence plate
                if (licenseNumber.match(/^C-[1-9]{1}[0-9]{0,4}$/)) {
                    return true;
                }

                // regular licence plate
                if (licenseNumber.match(/^[A-ZÅÄÖ]{2,3}-[1-9]{1}[0-9]{0,2}$/)) {
                    return true;
                }
                return false;
            }
    }

}

export default carDetailsReducer;