import { useDispatch, useSelector } from 'react-redux';
import { getDealerInfo, getTireChangeParametersOfAllDealers, getDealerCalendarParameters, getLeasingServices } from '../services/dataService';
import { getDateFromString, redirectToService, getUrlQueryParams } from './helperFunctions';
import { useIntl } from 'react-intl';

export const useDealer = () => {
    const { queryParameter } = useSelector(state => state.queryParameter);
    const intl = useIntl();
    const countryCode = intl.locale.split('-')[1];
    const dispatch = useDispatch();

    const getDealerInfoAsync = async (dealerIds) => {
        dispatch({ type: 'dealersSearching', payload: true });

        const dealersFromBackend = await getDealerInfo(dealerIds, countryCode);

        let queryParams = getUrlQueryParams();

        if (!queryParams) queryParams = queryParameter;

        if (dealersFromBackend && dealersFromBackend.length > 0) {
            const dealersWithWeekScheduleOrLeasing = await getDealersWithWeekScheduleOrLeasing(dealersFromBackend);

            const hasDealersWithTireChange = dealersWithWeekScheduleOrLeasing && dealersWithWeekScheduleOrLeasing.some(obj => obj.WeekSchedule);
            const hasDealersWithLeasingServices = dealersWithWeekScheduleOrLeasing && dealersWithWeekScheduleOrLeasing.some(obj => Array.isArray(obj.LeasingServices) && obj.LeasingServices.length > 0);

            if (!hasDealersWithTireChange && !hasDealersWithLeasingServices) {
                redirectToService(queryParams);
            }

            dispatch({ type: 'hasDealersWithTireChange', payload: hasDealersWithTireChange });
            dispatch({ type: 'hasDealersWithLeasing', payload: hasDealersWithLeasingServices });
            dispatch({ type: 'dealerListAdded', payload: dealersWithWeekScheduleOrLeasing });
        } else {
            redirectToService(queryParams);
        }

        dispatch({ type: 'dealersSearching', payload: false });
    };

    const dealersWithEnabledTireChangeExist = (tireChangeParameters) => {
        if (tireChangeParameters && tireChangeParameters.length > 0) {
            return tireChangeParameters.some((p) => {
                if (p.StartDate && p.EndDate) {
                    const startDate = getDateFromString(p.StartDate);
                    const endDate = getDateFromString(p.EndDate);
                    const today = new Date();

                    return startDate <= today && endDate >= today;
                }
                else if (p.StartDate && !p.EndDate) {
                    const startDate = getDateFromString(p.StartDate);
                    const today = new Date();

                    return startDate <= today;
                }

                return false;
            });
        }

        return false;
    };

    const getDealersWithWeekScheduleOrLeasing = async (dealersFromBackend) => {
        const dealersWithWeekScheduleOrLeasing = [];
        const dealerIds = dealersFromBackend.map(dealer => dealer.DealerId);
        const dealerTireChangeParameters = await getTireChangeParametersOfAllDealers(dealerIds);
        const leasingServices = await getLeasingServices();

        dealersFromBackend.forEach(dealer => {
            const dealerObj = dealer;

            if (dealerTireChangeParameters && dealersWithEnabledTireChangeExist(dealerTireChangeParameters)) {
                dealerTireChangeParameters.forEach(dealerParams => {
                    if ((dealerParams.StartDate && getDateFromString(dealerParams.StartDate) <= Date.now()) &&
                        (!dealerParams.EndDate || getDateFromString(dealerParams.EndDate) >= Date.now())) {

                        if (dealerObj.DealerId === dealerParams.DealerId) {
                            dealerObj.WeekSchedule = dealerTireChangeParameters.find(d => d.DealerId === dealerObj.DealerId);
                        }

                    }
                });
            }

            if (leasingServices && leasingServices.length > 0) {
                const dealerLeasingServices = leasingServices.filter(s => s.DealerId === dealer.DealerId);

                if (dealerLeasingServices && dealerLeasingServices.length > 0) dealerObj.LeasingServices = dealerLeasingServices;
            }

            if (dealerObj.WeekSchedule || (dealerObj.LeasingServices && dealerObj.LeasingServices.length > 0)) dealersWithWeekScheduleOrLeasing.push(dealerObj);
        });

        return dealersWithWeekScheduleOrLeasing;
    };

    return { getDealerInfoAsync };
};