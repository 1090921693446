import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import DealerDetails from '../DealerDetails/DealerDetails';
import SelectedTimeDetails from '../SelectedTimeDetails/SelectedTimeDetails';

const ThankYouPage = () => {

    const intl = useIntl();
    const countryCode = intl.locale.split('-')[1];

    return (

        <section id="thank-you" className="margin-top-m">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-l-scale padding-hor-l-scale">
                        <b><FormattedMessage id="summary.message.thanksForReservation" /></b>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-l-scale padding-hor-l-scale">
                        <DealerDetails />
                        <SelectedTimeDetails />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-10 offset-lg-1 bg-w padding-top-l-scale padding-hor-l-scale">
                        <a href={"?countryCode=" + countryCode}>
                            <FormattedMessage id="summary.backToFrontpage.linkText" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ThankYouPage;