import React, { useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useDealer } from '../../functions/dealerFunctions';
import { redirectToService, getEpaasTenant, getEpaasLocale } from '../../functions/helperFunctions';
import { queryParameterChanged } from '../../reducers/queryParameterReducer';

const FrontPage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { getDealerInfoAsync } = useDealer();

    const queryParams = new URLSearchParams(window.location.search);
    const dealerIdsFromUrl = queryParams.get('Dealers') || queryParams.get('dealers');
    const licenseFromUrl = queryParams.get('license');
    const mileageFromUrl = queryParams.get('mileage');

    const languageInfo = useSelector(state => state.language);
    const dealersInfo = useSelector(state => state.dealer);
    const { hasDealersWithTireChange } = useSelector(state => state.dealer);
    const { hasDealersWithLeasing } = useSelector(state => state.dealer);

    const browserCulture = document.documentElement.dataset.culture;
    const browserCountry = browserCulture.split('-')[1];
    const browserLang = browserCulture.split('-')[0];

    const selectedLang = languageInfo.locale ? languageInfo.locale.split('-')[0] : browserLang;

    const queryParamsFromState = useSelector(state => state.queryParameter.queryParameter);

    const queryParamsWithQuestionMark = queryParamsFromState ? queryParamsFromState :
        queryParams.size > 0 && queryParams.has('countryCode') ? `?${queryParams}&lang=${selectedLang}` :
            queryParams.size > 0 && !queryParams.has('countryCode') ? `?${queryParams}&countryCode=${browserCountry}&lang=${selectedLang}` :
                `?countryCode=${browserCountry}&lang=${selectedLang}`;

    const colOffsetClass = hasDealersWithTireChange && hasDealersWithLeasing ? 'offset-lg-0' : 'offset-lg-1';
    const colLgClass = hasDealersWithTireChange && hasDealersWithLeasing ? 'col-lg-4' : 'col-lg-5';
    const paddingClass = hasDealersWithTireChange && hasDealersWithLeasing ? 'padding-s' : 'padding-m';

    useEffect(() => {
        dispatch(queryParameterChanged(queryParamsWithQuestionMark));

        const currentUrl = window.location.href;

        if (currentUrl.includes('mini')) redirectToService(queryParamsWithQuestionMark);

        // cookie policy banner
        if (!document.getElementById('cookiePolicyScript')) {
            const ePaasTenant = getEpaasTenant(intl.formatMessage({ id: 'frontPage.cookiePolicy.epaas.tenant' }));
            const ePaasLocale = getEpaasLocale(intl.locale);

            const script = document.createElement('script');
            script.id = 'cookiePolicyScript';
            script.src = 'https://www.bmw.com/etc/clientlibs/wcmp/consentcontroller.fallback/epaas.js';
            script.async = true;
            script.onload = function () { epaas.api.initialize({ tenant: ePaasTenant, locale: ePaasLocale, async: true }); };
            document.body.appendChild(script);
        }

        if (licenseFromUrl) {
            dispatch({
                type: 'licenseInfoChanged', payload: { license: licenseFromUrl, locale: browserCulture }
            });
        }

        if (mileageFromUrl) {
            dispatch({
                type: 'mileageInfoChanged', payload: mileageFromUrl
            });
        }

        if (dealersInfo.dealers.length === 0) {
            getDealerInfoAsync(dealerIdsFromUrl);
        }
        else
        {
            dispatch({
                type: 'hasDealersWithTireChange',
                payload: true
            });
        }
    }, [dispatch]);

    const disabledClassIfLinkIsDisabled = () => {
        return dealersInfo.dealers.length === 0 ? 'btn disabled' : '';
    };

    if (!hasDealersWithTireChange && !hasDealersWithLeasing) return null; // To prevent the app from being rendered before redirecting user to service site

    return (
        <div id="page1">
            <Header
                title={<FormattedMessage id="frontPage.header" />}
                description={<FormattedMessage id="frontPage.description" />}
            />
            <main id="main">

                <div className="container">
                    <div className="row d-flex">
                        <div className={`col-12 ${colLgClass} ${colOffsetClass} bg-w fp_box-padding col-vertical padding-top-s`}>
                            <img src="/Scripts/src/media/images/Tuulilasi.JPG" alt="" />
                            <div className={`${paddingClass} col-vertical box-content`}>
                                <h2>
                                    <FormattedMessage id="frontPage.serviceBooking.header" />
                                </h2>
                                <p><FormattedMessage id="frontPage.serviceBooking.description" /></p>
                                <a href={'/service' + queryParamsWithQuestionMark} className="bmw-btn btn-link valign-bottom">
                                    <FormattedMessage id="frontPage.serviceBooking.link" />
                                </a>
                            </div>
                        </div>
                        {hasDealersWithTireChange &&
                            <div className={`col-12 ${colLgClass} bg-w fp_box-padding col-vertical padding-top-s`}>
                                <img src="/Scripts/src/media/images/TyreChangeResized.jpg" alt="" />
                                <div className={`${paddingClass} col-vertical box-content`}>
                                    <h2>
                                        <FormattedMessage id="frontPage.tireChange.header" />
                                    </h2>

                                    <span>
                                        <p><FormattedMessage id="frontPage.tireChange.description" /></p>
                                        <Link to={'/tirechange'} className={'bmw-btn btn-link valign-bottom ' + disabledClassIfLinkIsDisabled()} aria-disabled={dealersInfo.dealers.length === 0}>
                                            <FormattedMessage id="frontPage.tireChange.link" />
                                        </Link>
                                    </span>

                                    <span hidden={dealersInfo.dealersSearching === true || dealersInfo.dealers.length > 0}>
                                        <FormattedMessage id="frontPage.tireChange.error.noDealersAvailable" />
                                    </span>
                                </div>
                            </div>
                        }
                        {hasDealersWithLeasing &&
                            <div className={`col-12 ${colLgClass} bg-w fp_box-padding col-vertical padding-top-s`}>
                                <img src="/Scripts/src/media/images/BG_Banner4.jpg" alt="" />
                                <div className={`${paddingClass} col-vertical box-content`}>
                                    <h2>
                                        <FormattedMessage id="frontPage.leasing.header" />
                                    </h2>

                                    <span>
                                        <p><FormattedMessage id="frontPage.leasing.description" /></p>
                                        <Link to={'/leasing'} className={'bmw-btn btn-link valign-bottom ' + disabledClassIfLinkIsDisabled()} aria-disabled={dealersInfo.dealers.length === 0}>
                                            <FormattedMessage id="frontPage.tireChange.link" />
                                        </Link>
                                    </span>

                                    <span hidden={dealersInfo.dealersSearching === true || dealersInfo.dealers.length > 0}>
                                        <FormattedMessage id="frontPage.tireChange.error.noDealersAvailable" />
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default FrontPage;