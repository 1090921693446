import React, { useEffect } from 'react';
import DealerSelection from '../DealerSelection/DealerSelection';
import CarDetails from '../CarDetails/CarDetails';
import ServicesSelection from '../ServicesSelection/ServicesSelection';
import ServiceTimeSelection from '../ServiceTimeSelection/ServiceTimeSelection';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

const VehicleInformation = () => {
    const dispatch = useDispatch();
    const currentCulture = document.documentElement.dataset.culture;
    const title = window.location.href.includes('/tirechange') ? <FormattedMessage id="page2.header" /> : <FormattedMessage id="page2.headerLeasing" />;

    useEffect(() => {
        getVehicleInfoFromUrl();
    }, []);

    const getVehicleInfoFromUrl = () => {
        const urlQueryParams = new URLSearchParams(window.location.search);
        const licenseFromUrl = urlQueryParams.get('license');
        const mileageFromUrl = urlQueryParams.get('mileage');

        if (licenseFromUrl) {
            dispatch({
                type: 'licenseInfoChanged', payload: { license: licenseFromUrl, locale: currentCulture }
            });
        }

        if (mileageFromUrl) {
            dispatch({
                type: 'mileageInfoChanged', payload: mileageFromUrl
            });
        }
    };

    return (
        <div id="page2">
            <Header title={title} />
            <div>
                <main id="main">
                    <DealerSelection />
                    <CarDetails />
                    <ServicesSelection />
                    <ServiceTimeSelection />
                </main>
            </div>
            <Footer />
        </div>
    );
};

export default VehicleInformation;
