const NO_ERROR = 'noError';
const initialState = {

    FirstName: { value: '', error: 'customerInfo.firstName.error.missing' },
    LastName: { value: '', error: 'customerInfo.lastName.error.missing' },
    Email: { value: '', error: 'customerInfo.email.error.missingOrInvalid' },
    PhoneNumber: { value: '', error: 'customerInfo.mobilephone.error.missing' },
    Info: { value: '', error: NO_ERROR },
    MarketingAllowed: { value: false, error: NO_ERROR },
    //AnalyticsAllowed: { value: false, error: NO_ERROR },
    DataSafetyInfo: { value: false, error: 'customerInfo.dataSafetyInfo.read.dataSafetyInfo' },
    HasInputErrors: true,
    MarketingAllowedTooltipTextVisibility: 'hide',
    AnalyticsAllowedTooltipTextVisibility: 'hide',
    AllowanceCancelTooltipTextVisibility: 'hide'
};



const customerReducer = (state = initialState, action) => {
    let error = NO_ERROR;

    if (action.payload && action.payload.name) {
        error = handleValidation(action.payload.value, action.payload.name);
    }

    switch (action.type) {

        case 'RESET_STORE':
            return initialState;

        case 'FirstNameUpdated':
            return {
                ...state, FirstName: { value: action.payload.value, error: error }, HasInputErrors: error !== NO_ERROR || hasAnyInputErrors(state, action.payload.name)
            }

        case 'LastNameUpdated':
            return {
                ...state, LastName: { value: action.payload.value, error: error }, HasInputErrors: error !== NO_ERROR || hasAnyInputErrors(state, action.payload.name)
            }

        case 'EmailUpdated':
            return {
                ...state, Email: { value: action.payload.value, error: error }, HasInputErrors: error !== NO_ERROR || hasAnyInputErrors(state, action.payload.name)
            }

        case 'PhoneNumberUpdated':
            return {
                ...state, PhoneNumber: { value: action.payload.value, error: error }, HasInputErrors: error !== NO_ERROR || hasAnyInputErrors(state, action.payload.name)
            }

        case 'InfoUpdated':
            return {
                ...state, Info: { value: action.payload.value, error: error }, HasInputErrors: error !== NO_ERROR || hasAnyInputErrors(state, action.payload.name)
            }

        case 'MarketingAllowedUpdated':
            return {
                ...state, MarketingAllowed: { value: action.payload.value, error: error }, HasInputErrors: error !== NO_ERROR || hasAnyInputErrors(state, action.payload.name)
            }

        //case 'AnalyticsAllowedUpdated':
        //    return {
        //        ...state, AnalyticsAllowed: { value: action.payload.value, error: error }, HasInputErrors: error !== NO_ERROR || hasAnyInputErrors(state)
        //    }

        case 'DataSafetyInfoUpdated':
            return {
                ...state, DataSafetyInfo: { value: action.payload.value, error: error }, HasInputErrors: error !== NO_ERROR || hasAnyInputErrors(state, action.payload.name)
            }

        case 'MarketingAllowedTooltipTextVisibilityChanged':
            return {
                ...state, MarketingAllowedTooltipTextVisibility: action.payload
            }

        case 'AnalyticsAllowedTooltipTextVisibilityChanged':
            return {
                ...state, AnalyticsAllowedTooltipTextVisibility: action.payload
            }

        case 'AllowanceCancelTooltipTextVisibilityChanged':
            return {
                ...state, AllowanceCancelTooltipTextVisibility: action.payload
            }


        default:
            return state;
    }

    function hasAnyInputErrors(state, currentFieldName) {
        let hasErrors = false;
        Object.keys(state).forEach((key) => {
            if (key !== currentFieldName && state[key].error && state[key].error !== NO_ERROR) {
                hasErrors = true;
            };
        });

        return hasErrors;
    }

    function handleValidation(fieldValue, fieldName) {

        if (fieldName === 'FirstName' && !fieldValue) {
            return "customerInfo.firstName.error.missing";
        }

        if (fieldName === 'LastName' && !fieldValue) {
            return "customerInfo.lastName.error.missing";
        }

        if (fieldName === 'Email' && (!fieldValue || !isValidEmail(fieldValue))) {
            return "customerInfo.email.error.missingOrInvalid";
        }

        if (fieldName === 'PhoneNumber' && !fieldValue) {
            return "customerInfo.mobilephone.error.missing";
        }

        if (fieldName === 'DataSafetyInfo' && !fieldValue) {
            return "customerInfo.dataSafetyInfo.read.dataSafetyInfo";
        }

        return NO_ERROR;
    }

    function isValidEmail(email) {
        const emailRegex =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return emailRegex.test(email);
    }
}

export default customerReducer;