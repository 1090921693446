import VehicleInformation from './components/VehicleInformation/VehicleInformation';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Summary from './components/Summary/Summary';
import FrontPage from './components/FrontPage/FrontPage';
import CookiePolicy from './components/CookiePolicy/CookiePolicy';
import { IntlProvider } from 'react-intl';
import Finnish from './lang/fi.json';
import Swedish from './lang/se.json';
import Norwegian from './lang/no.json';
import Estonian from './lang/ee.json';
import Latvian from './lang/lv.json';
import Danish from './lang/da.json';
import Lithuanian from './lang/lt.json';

let countryCode = null;
let lang, locale;

const queryParams = new URLSearchParams(window.location.search);

const origin = window.origin;
const originCountry = origin.substring(origin.length - 2, origin.length);

queryParams.forEach(function (value, key) {
    if (key.toLowerCase() === 'countrycode' && value) {
        countryCode = value.toLowerCase();
    }
});

if ((countryCode && countryCode === "se") || originCountry === "se") {
    lang = Swedish;
    locale = "sv-SE";
}
// nb-NO or nn-NO locales for Norwegian are not recognized by many browsers, locale da-NO has to be used.
else if ((countryCode && countryCode === "no") || originCountry === "no") {
    lang = Norwegian;
    locale = "da-NO";
}
else if ((countryCode && countryCode === "ee") || originCountry === "ee") {
    lang = Estonian;
    locale = "et-EE";
}
else if ((countryCode && countryCode === "lv") || originCountry === "lv") {
    lang = Latvian;
    locale = "lv-LV";
}
else if ((countryCode && countryCode === "lt") || originCountry === "lt") {
    lang = Lithuanian;
    locale = "lt-LT";
}
else if ((countryCode && countryCode === "dk") || originCountry === "dk") {
    lang = Danish;
    locale = "da-DK";
}
else {
    countryCode = "fi";
    lang = Finnish;
    locale = "fi-FI";
}

const App = () => {
    const language = useSelector(state => state.language);

    if (language.locale && language.messages) {
        locale = language.locale;
        lang = language.messages;
    }

    return (
        <IntlProvider locale={locale} messages={lang}>
            <Switch>
                <Route path='/cookiepolicy' component={CookiePolicy}>
                </Route>
                <Route path='/' exact component={FrontPage}>
                </Route>
                <Route path='/tirechange' component={VehicleInformation}>
                </Route>
                <Route path='/leasing' component={VehicleInformation}>
                </Route>
                <Route path='/reservation' component={Summary}>
                </Route>
            </Switch>
        </IntlProvider>
    );
};

export default App;
