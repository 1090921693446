import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const Footer = () => {

    const intl = useIntl();
    const { versionNumber } = window.AppSettings || {};

    return (
        <div>
            <footer id="page-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-wrap">
                                <ul>
                                    <li><a href={intl.formatMessage({ id: "footer.legalNotice.url" })} target="_blank"><FormattedMessage id="footer.legalNotice" /></a></li>
                                    <li><a href="/service/cookiepolicy" target="_blank"><FormattedMessage id="footer.cookiePolicy" /></a></li>
                                    <li><a href={intl.formatMessage({ id: "footer.privacyPolicy.url" })} target="_blank"><FormattedMessage id="footer.privacyPolicy" /></a></li>
                                </ul>
                                <span>
                                    &copy; BMW AG 2024, v.{versionNumber}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;