import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import './styles/scss/bmw.scss';

import 'jquery';
import 'bootstrap/dist/js/bootstrap';

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>,
    document.getElementById("root")
);